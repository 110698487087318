<template>
  <page-view :title="title" left-arrow>
    <div class="func-list">
      <div v-for="item in funcList" :key="item.id" class="func-item">
        <div class="func-card" @click="itemClickHandle(item.id)">
          <div class="func-card__icon">
            <van-icon :name="item.icon" size="36" :style="{marginRight: '10px'}" />
          </div>
          <div class="func-card__content">
            <div class="func-card__title">{{item.title}}</div>
            <p class="func-card__text">{{item.desc}}</p>
          </div>
        </div>
      </div>
    </div>
  </page-view>
</template>

<script>
  import {
    invoiceOpen
  } from '@/api/invoice';
  export default {
    name: 'Ywbl',
    data() {
      return {
        title: '业务办理',
        funcList: [
          { 
            id: 'ydxz',
            icon: require('@/assets/icons/user-link.png'),
            title: '用电新装',
            desc: '用电新装申请'
          },
          { 
            id: 'ydbg',
            icon: require('@/assets/icons/user-link.png'),
            title: '用电变更',
            desc: '用电变更申请'
          },
          { 
            id: 'gdcx',
            icon: require('@/assets/icons/djcx.png'),
            title: '业务进度查询',
            desc: '业务办理进度查询'
          },
          // { 
          //   id: 'ydxh',
          //   icon: require('@/assets/icons/user-link.png'),
          //   title: '用电销户',
          //   desc: '用电销户申请'
          // },
        ]
      }
    },
    methods: {
      itemClickHandle(id) {
        this.$router.push(id);
      }
    },
  }
</script>

<style lang="scss" scoped>
  .van-col--12 {
    width: 50%;
  }
  .func-list {
    margin: 10px; 
    display: flex; 
    flex-wrap: wrap;
  }
  .func-item {
    margin-bottom: 10px; 
    // padding: 0 5px; 
    // flex: 1 1 50%; 
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .func-card {
    border-radius: 8px;
    padding: 16px;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 1px 10px -5px #e2e2e2;
    display: flex;
    &:hover,
    &:active 
    {
      background-color: #e0e0e0;
    }
  }
  
  .func-card__content {
    flex: 1;
  }
  .func-card__title {
    font-size: 18px;
    color: #4c4c4c;
    line-height: 1;
    margin-bottom: 8px;
  }

  .func-card__text {
    font-size: 12px;
    color: #999;
  }
</style>