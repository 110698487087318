import fetch from '@/utils/fetch';

/**
 * 根据用户编号查询电子发票信息
 * @param {String} yhbh 
 * @param {Object} pagination 
 * @param {} meta 
 */
export function zwLjfysmx_getDzfpByYhbh(yhbh, pagination, meta) {
	return fetch({
		url: 'psdmsdfzw/sfglschema/ZwLjfysmx/getDzfpByYhbh',
		method: 'post',
		data: {
			param: {
				yhbh: yhbh // Sstring
			},
			pagination: pagination
		}
	});
}

/* ---
开票
*/
export function invoiceOpen(data, meta) {
	return fetch({
		url : 'invoice/invoiceOpen',
		method : 'post',
		data : data
	})
}
